import './App.css';
import { TVChartContainer } from './components/TVChartContainer/index';
import { useLocation } from 'react-router-dom';

const App = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pair = queryParams.get('pair')  || "BTC-USDT";
    const mode = queryParams.get('mode') || "dark";
    let from = pair.split("-")[0];
    let to = pair.split("-")[1];
    const pairInfo = { pair: from+to, streamPair: 'btcusdt', toCurrency: to, fromCurrency: from,thememode:mode }
    return (
        <div className={'App'}>
            <TVChartContainer pairInfo={pairInfo} />
        </div>
    );
}

export default App;
